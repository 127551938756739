@import "@co/style";

.estdet {
  padding-top: 60px;
  .estdetBody {
    @include cenTheWid;
    margin: 40px auto;
    .edbTop {
      display: flex;
      .edbtImg {
        width: 712px;
        height: 528px;
        margin-right: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .edbtBig {
          width: 712px;
          height: 400px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .edbtLit {
          height: 104px;
          width: 712px;
          position: relative;
          overflow: hidden;
          .edbtlLeft {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 40px;
            background: rgba(0, 0, 0, 0.3);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .edbtlCen {
            display: flex;
            transition: all 0.5s ease-in-out;
            img {
              width: 186px;
              height: 104px;
              margin-right: 24px;
              cursor: pointer;
              flex-shrink: 0;
            }
            .act {
              border: 1px dashed #333;
              box-sizing: border-box;
            }
          }
          .edbtlRig {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 40px;
            background: rgba(0, 0, 0, 0.3);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
      }
      .edbtInfo {
        width: calc(100% - 752px);
        & > h5 {
          @include ellipsis;
          font-size: 24px;
          width: 100%;
          height: 36px;
          margin: 0;
        }
        .edbtiType {
          margin: 12px 0 15px 0;
          span {
            padding: 5px 8px;
            background: #e8f0ff;
            font-size: 14px;
            color: #1e70ff;
          }
        }
        .edbtiAdr {
          display: flex;
          align-items: center;
          img {
            margin-right: 3px;
          }
          span {
            font-size: 14px;
            color: #6c7a93;
          }
        }
        .edbtiNum {
          border-bottom: 1px solid #dfe5ef;
          border-top: 1px solid #dfe5ef;
          margin: 15px 0 15px;
          display: flex;
          padding: 8px 0;
          justify-content: space-around;
          & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            height: 70px;
            & > div:first-child {
              color: #1e70ff;
              font-size: 20px;
              font-weight: bolder;
            }
            & > div:last-child {
              font-size: 14px;
              color: #6c7a93;
            }
          }
        }
      }
      .edbtDet {
        margin: 25px 0 0 0;
        & > div {
          margin: 16px 0;
          font-size: 14px;
          label {
            color: #6c7a93;
            margin-right: 15px;
          }
          span {
            color: #2b3749;
          }
        }
      }
      .edbtMet {
        display: flex;
        margin-top: 82px;
        & > div {
          width: 208px;
          height: 48px;
          display: flex;
          align-items: center;
          color: #ffffff;
          justify-content: center;
          font-size: 16px;
          & > img {
            margin-right: 10px;
          }
        }
        & > div:first-child {
          cursor: pointer;
          background: #1e70ff;
        }
        & > div:last-child {
          margin-left: 32px;
          background: #ffa706;
        }
      }
    }
    .edbCen {
      .edbcBody {
        .edbcbItem {
          display: flex;
          margin: 40px 0;
          .edbcImg {
            width: 448px;
            height: 336px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            .edbcBig {
              width: 100%;
              height: 100%;
            }
            .edbci1,
            .edbci2 {
              position: absolute;
              top: 50%;
              cursor: pointer;
            }
            .edbci1 {
              left: 10px;
            }
            .edbci2 {
              right: 10px;
            }
            .edbciNum {
              position: absolute;
              bottom: 10px;
              right: 10px;
              height: 24px;
              background: rgba(0, 0, 0, 0.5);
              color: #ffffff;
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 10px;
              border-radius: 8px;
            }
          }
          .edbcCon {
            margin-left: 40px;
            & > h5 {
              font-weight: bold;
              font-size: 16px;
              margin: 0 0 20px 0;
            }
            .edbccType {
              span {
                padding: 4px 8px;
                font-size: 12px;
              }
              .edbccj {
                background: #e8f0ff;
                color: #1e70ff;
                margin-right: 10px;
              }
              .edbcc1 {
                color: #76c72f;
                background: #f1f9ea;
              }
              .edbcc2 {
                color: #ffa706;
                background: #fff6e6;
              }
            }
          }
        }
      }
    }
    .edbBot {
      .edbBBody {
        display: flex;
        flex-wrap: wrap;
        .ppybc-item {
          transition: all 0.3s;
          box-sizing: border-box;
          width: 50%;
          display: flex;
          margin: 15px 0;
          padding: 24px;
          border-radius: 8px;
          cursor: pointer;
          .ppybci-left {
            // width: 40%;
            height: 176px;
            width: 236px;
            margin: 0 28px 0 0;
            position: relative;
            .ppybcil-img {
              // width: 100%;
              width: 236px;
              height: 176px;
              border-radius: 5px;
            }
            .ppybcil-tips {
              position: absolute;
              bottom: 12px;
              left: 12px;
              height: 32px;
              line-height: 32px;
              background: rgba(0, 0, 0, 0.5);
              border-radius: 30px;
              display: flex;
              max-width: 215px;
              & > span {
                color: #fff;
                font-size: 16px;
                padding: 0 10px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              & > img {
                margin: 0 0px 0 15px;
                width: 14px;
              }
            }
            .ppybcil-tip {
              position: absolute;
              bottom: 12px;
              left: 0;
              height: 32px;
              line-height: 32px;
              background: #e8f0ff;
              // border-radius: 30px;
              display: flex;
              max-width: 215px;
              color: #1e70ff;
              padding: 0 10px;
            }
          }
          .ppybcil-lnIBox {
            display: flex;
            justify-content: center;
            border-radius: 5px;
            border: 1px solid #eff2f7;
            .ppybcil-tips {
              background: rgba(43, 55, 73, 0.5);
            }
            .ppybcil-img {
              width: 140px;
              height: 120px;
              margin-top: 16px;
            }
          }
          .ppybci-right {
            // position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 53%;
            & > h5 {
              margin: 0;
              font-size: 24px;
              font-weight: bold;
              // width: 240px;
              // overflow: hidden;
              // white-space: nowrap;
              // text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
            }
            & > div {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              width: 100%;
              & > div {
                // display: flex;
                width: 100%;
                & > div {
                  color: #6c7a93;
                  font-size: 16px;
                  // margin: 14px 0 0px;
                  height: 30px;
                  display: flex;
                  align-items: center;
                }
                .ppybcir-adr > span {
                  @include ellipsis;
                  width: 100%;
                }
              }
              & > button {
                border-radius: 25px;
                border-color: #40a9ff;
                color: #40a9ff;
                font-size: 16px;
                height: 40px;
                // position: absolute;
                // left: 0;
                // bottom: 0;
              }
            }
            & img {
              margin: 0 10px 0 0;
            }
          }
        }
        .ppybc-item:hover {
          // transform: translateY(-1px);
          box-shadow: 1px 1px 10px 2px #ccc;
          div div button {
            background: #1e70ff;
            border-color: #1e70ff;
            color: #fff;
          }
        }
        .ppybc-conf {
          width: 100%;
          text-align: center;
          margin: 100px 0;
          div {
            height: 16px;
            font-size: 16px;
            font-weight: 400;
            color: #6c7a93;
            margin: 50px 0 0 0;
          }
        }
      }
    }
    .edbHead {
      height: 60px;
      margin-top: 50px;
      h1 {
        font-size: 40px;
        position: relative;
        margin-left: 20px;
        &::before {
          position: absolute;
          left: -20px;
          top: 11px;
          content: "";
          width: 8px;
          height: 35px;
          background: #1e70ff;
        }
      }
    }
  }
}

.station-item-info-div {
  width: 782px;
  margin: 15px 0 0 0;
  // display: flex;
  // justify-content: space-between;
}
.station-item-info-inndiv {
  display: flex;
}
.station-item-info-half {
  // width: 351px;
  width: 33.33%;
  display: flex;
  flex-direction: column;
}
.station-item-info-all {
  width: 100%;
}
.station-item-info-half-content-div {
  // width: 351px;
  display: flex;
  padding: 11px 0;
  // border-bottom: 1px solid #eff2f7;
}
.station-item-info-all-content-div {
  display: flex;
  padding: 11px 0;
}
.station-item-info-half-content-div .title {
  width: 100px;
  height: 16px;
  line-height: 16px;
  font-size: 16px;
  color: #6c7a93;
}
.station-item-info-all-content-div .title {
  width: 100px;
  height: 16px;
  line-height: 16px;
  font-size: 16px;
  color: #6c7a93;
  display: block;
}
.station-item-info-half-content-div .content {
  width: 100px;
  line-height: 24px;
  font-size: 16px;
  color: #2b3749;
  display: block;
  margin-top: -4px;
}
.station-item-info-all-content-div .content {
  width: calc(100% - 100px);
  line-height: 24px;
  font-size: 16px;
  color: #2b3749;
  display: block;
  margin-top: -4px;
}
.station-item-all-desc-div {
  width: 228px;
  display: flex;
  flex-direction: column;
  margin-top: -4px;
}
.station-item-all-desc-content {
  width: 228px;
  line-height: 24px;
  font-size: 16px;
  color: #2b3749;
}
.station-item-part-desc-content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: 228px;
  line-height: 24px;
  font-size: 16px;
  color: #2b3749;
}
.station-item-all-desc-blue-span {
  height: 16px;
  line-height: 16px;
  font-size: 16px;
  color: #1e70ff;
  cursor: pointer;
}
.station-item-sns-div {
  width: calc(100% - 100px);
  // width: 228px;
  display: flex;
  flex-wrap: wrap;
  // flex-direction: column;
}
.station-item-sn-item {
  height: 16px;
  line-height: 16px;
  font-size: 16px;
  // display: block;
  color: #2b3749;
  margin-bottom: 8px;
}
