.cdet{width: 100%;min-width: 1280px;}.cdet-box {
  width: 1280px;
  margin: 0 auto;
  .cdetb-wrap{
     .cdetbw-head{
         margin: 128px 0 0;
         &>h1{
             font-size: 32px;
         }
         &>div{
             margin: 32px 0 0;
             padding-bottom: 32px;
             font-size: 16px;
             color: #6C7A93;
             border-bottom: 1px solid #EFF2F7;
             display: flex;
             justify-content: space-between;
         }
     } 
     .cdetbw-body{
        //  margin: 10px 0;
         padding: 40px 0;
         &>img{
             margin: 25px 0;
         }
         .cdetbwb-img{
             margin-top: 50px;
         }
     }
  }
}
