.eey {
  min-width: 1000px;
  transition: all 0.3s;
  padding: 64px 0 0 0;
  .eey-head {
    .eey-head-img {
      width: 100%;
      height: 480px;
      object-fit: cover;
    }
  }
  .eey-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
    .eey-phb {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 1000px;
    }
    .eey-headTxt {
      font-size: 40px;
      width: 1000px;
      margin: 100px auto 40px;
      text-align: center;
      position: relative;
      &::after {
        position: absolute;
        bottom: -17px;
        left: 50%;
        transform: translate(-50%);
        content: "";
        width: 130px;
        height: 5px;
        background: #206fff;
      }
    }
    .eey-phb-box {
      position: relative;
      margin: 10px 0;
      .more {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 50px;
        width: 100%;
        cursor: pointer;
        // background: red;
      }
    }
    .eey-com-body{
      display: flex;
      flex-wrap: wrap;
      width: 1077px;
      justify-content: space-between;
      .eey-item-con{
        cursor: pointer;
        &:hover{
          margin-top: -5px;
          transition: all .3s;
        }
      }
    }
  }
}
