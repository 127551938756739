@import "@co/style";

.menu {
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 3;
  width: 100%;
  box-shadow: 0px 4px 16px 1px rgba(43, 55, 73, 0.2);
  &.show {
    @include showMenu;
  }
  &.hide {
    @include hideMenu;
  }
  .menuBox {
    max-width: $themeMaxWidth;
    @include themeWidth;
    display: flex;
    justify-content: space-between;
    align-content: center;
    height: 64px;
    margin: 0 auto;
    background: #fff;
    .left {
      display: flex;
      align-items: center;
      & > img {
        cursor: pointer;
      }
      .telBox {
        margin-left: 40px;
        display: flex;
        align-items: center;
        & > span {
          // font-family: PuHuiTiM;
          font-size: 16px;
          color: #1e70ff;
          margin-left: 16px;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      & > div {
        margin: 0 0 0 5px;
        background-color: #fff;
        height: 100%;
        display: flex;
        align-items: center;
        transition: all 0.5s;
        a {
          text-decoration: none;
          transition: all 0.5s;
          height: 100%;
          width: 100%;
          padding: 0 20px;
          display: flex;
          align-items: center;
          // font-family: PuHuiTiM;
          font-size: 16px;
          color: #6c7a93;
        }
      }
      & > div:hover {
        background: #1e70ff;
        a {
          color: #fff;
        }
      }
      .menuActive {
        background: #1e70ff;
        a {
          color: #fff;
        }
      }
    }
  }
  @media screen and (max-width: 1280px) {
    position: absolute;
  }
}
