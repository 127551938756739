.edet {
  width: 100%;
  min-width: 1000px;
}
.edet1 {
  background: linear-gradient(to bottom, #e4f9ff, #fbfbfb);
  .head {
    .title-box {
      .first {
        color: #2cc0e7;
      }
      .second {
        color: #a1c9d2;
      }
    }
  }
  .body {
    .title-box {
      background: linear-gradient(264.38deg, #a1ecff 0.67%, #2bbfe7 91.88%);
    }
  }
}
.edet2 {
  background: linear-gradient(
    180deg,
    #f8f6ff 0%,
    #ffffff 89.15%,
    #fbfbfb 89.15%
  );
  .head {
    .title-box {
      .first {
        color: #6551cb;
      }
      .second {
        color: #bcb6db;
      }
    }
  }
  .body {
    .title-box {
      background: linear-gradient(264.38deg, #c4b7ff 0.67%, #886eff 91.88%);
    }
  }
}
.edet3 {
  background: linear-gradient(180deg, #d7e7fe 0%, #fbfbfb 89.15%);

  .head {
    .title-box {
      .first {
        color: #1458be;
      }
      .second {
        color: #85a3be;
      }
    }
  }
  .body {
    .title-box {
      background: linear-gradient(264.38deg, #5999ff 0.67%, #1a72ff 91.88%);
    }
  }
}
.edet4 {
  background: linear-gradient(
    180deg,
    #eeffed 0%,
    #fbfbfb 89.14%,
    #ffffff 89.15%
  );

  .head {
    .title-box {
      .first {
        color: #539453;
      }
      .second {
        color: #bddbb6;
      }
    }
  }
  .body {
    .title-box {
      background: linear-gradient(264.38deg, #93e3ae 0.67%, #4bc44b 91.88%);
    }
  }
}
.edet-box {
  width: 1000px;
  margin: 64px auto 200px;
  padding: 40px 0 0 0;
  .head {
    display: flex;
    margin: 20px 0;
    padding-top: 20px;
    .title-box {
      margin-left: 20px;
      .first {
        font-size: 22px;
        font-weight: bold;
      }
      .second {
        font-size: 14px;
      }
    }
  }
  .body {
    .title-box {
      color: #fff;
      height: 90px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      padding-left: 30px;

      .first {
        font-size: 20px;
        font-weight: bold;
      }
      .second {
        font-size: 12px;
        margin-top: 5px;
      }
    }
    .body-box {
      padding: 0 20px;
      box-shadow: 0px 0px 40px 0px #79839226;

      .body-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        border-bottom: 1px solid #f0f0f0;

        &:hover {
          transition: all 0.2s;
          color: #2cc0e7;
          & > div:first-child {
            transition: all 0.3s;
            font-size: 17px;
          }
        }

        & > div:first-child {
          font-weight: bold;
          font-size: 16px;
          .number {
            margin: 0 18px 0 0;
            font-style: italic;
            color: #a3a8ad;
            font-family: Roboto;
          }
          .number1 {
            color: #f95243;
          }
          .number2 {
            color: #ff7a00;
          }
          .number3 {
            color: #ffc700;
          }
        }
        & > div:last-of-type {
          display: flex;
          align-items: center;
          .star {
            display: flex;
            align-items: center;
          }
          & > span:first-of-type {
            margin: 0 0 0 20px;
            color: #a3a8ad;
            font-size: 12px;
          }
          & > span:last-of-type {
            font-size: 14px;
            font-style: italic;
          }
        }
      }
    }
  }
}
