.swiperWrap {
  position: relative;
  width: 100%;
  margin-bottom: -5px;
  .swiperFixBox {
    position: absolute;
    left: 0;
    top: 72.5%;
    z-index: 2;
    width: 50%;
    height: 13%;
    background: #19458f;
    & > div {
      font-size: 40px;
      // font-family: PuHuiTiB;
      font-weight: bold;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      margin-right: 15%;
    }
  }
  .swiper {
    // min-width: 1280px;
    width: 100%;
    .swiper-wrapper {
      .swiper-slide {
        position: relative;
        .txtWrap {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          // background: red;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .txtBox {
            width: 1000px;
            // background: red;
            height: 280px;
          }
          .title {
            font-weight: bold;
            font-size: 40px;
            color: #ffffff;
            // margin: 17% 0 0 0;
          }
          .content {
            font-weight: 400;
            font-size: 16px;
            line-height: 27px;
            color: #ffffff;
            margin: 30px 0 0 0;
            width: 80%;
          }
        }
        img {
          width: 100%;
          height: 100%;
          min-height: 500px;
          max-height: 800px;
          // object-fit: cover;
          // object-position: 50% 50%;
        }
      }
    }
    .swiper-pagination {
      margin-bottom: 10px;
      .pages {
        width: 150px;
        background: #888;
        background: rgba(255, 255, 255, 0.2);
        opacity: 1;
        height: 3px;
        border-radius: 0;
      }
      .curPages {
        position: relative;
        background: #888;
        opacity: 1;
        background: rgba(255, 255, 255, 0.2);
      }
      .curPages::after {
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        height: 3px;
        opacity: 1;
        background: #fff;
        background: rgba(255, 255, 255, 1);
        animation: mymove 3.3s ease-in-out;
        @keyframes mymove {
          0% {
            width: 0;
          }
          100% {
            width: 100%;
          }
        }
      }
    }

    //   .swiperFix{
    //       position: absolute;
    //       z-index: 3;
    //       left: 30%;
    //       top: 10%;
    //       width: 30%;
    //       max-width: 500px;
    //       min-width: 300px;
    //       height: 80%;
    //       max-height: 80%;
    //       min-height: 40%;
    //       background: red;
    //   }
  }
}
