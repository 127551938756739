.aot {
  min-width: 1200px;
  transition: all 0.3s;
  .aot-head {
    .aoth-banner {
      width: 100%;
      height: 480px;
      object-fit: cover;
    }
  }
  .aot-body {
    // width: 1200px;
    // margin: 50px auto;
    padding: 40px 0 100px;
    .aot-headTxt {
      font-size: 40px;
      width: 1200px;
      margin: 100px auto 40px;
      text-align: center;
      position: relative;
      &::after {
        position: absolute;
        bottom: -17px;
        left: 50%;
        transform: translate(-50%);
        content: "";
        width: 130px;
        height: 5px;
        background: #206fff;
      }
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 27px;
      /* or 193% */

      text-align: center;

      color: #8a95a9;
    }
    .aotb-item {
      width: 1200px;
      margin: 10px auto;
      display: block;
    }
    .aotb-itemBbox {
      .aotbi-box {
        margin: 70px 0 0 0;
        display: flex;
        justify-content: center;
      }
    }
    .swiperWrapZZ {
      margin: 30px 0;
      // height: 520px;
      position: relative;
      .swiper-slide {
        height: 350px;
        display: flex;
        align-items: center;
        img {
          width: 100%;
        }
      }
      .swiper-button-prev {
        position: absolute;
        top: 45%;
        left: -55px;
        z-index: 1;
        cursor: pointer;
      }
      .swiper-button-next {
        position: absolute;
        top: 45%;
        right: -55px;
        z-index: 1;
        cursor: pointer;
      }
    }
  }
}
