@import "@co/style";

.estate {
  margin: 0;
  padding: 60px 0 0 0;
  @include themeWidth;
  .eaeBody {
    padding-bottom: 55px;
    .ppy-head {
      background: #eff2f7;
      .ppyb-box {
        width: 1200px;
        margin: 0 auto;
        @include cenTheWid;
        padding: 48px 0 50px 0;
        .ppyb-sh {
          display: flex;
          height: 40px;
          justify-content: flex-start;
          margin: 0 0 30px 0;
          position: relative;
          width: 600px;
          .ppybs-ser {
            height: 100%;
            width: 600px;
            padding-left: 10px;
            .ant-input {
              border-color: #dfe5ef;
              font-size: 16px;
              padding: 7px;
            }
            .ant-input::-webkit-input-placeholder {
              font-size: 16px;
            }
            .ant-btn {
              font-size: 16px;
              height: 40px;
            }
          }
          .ppybs-btn {
            position: absolute;
            right: 0;
            background: #ffa706;
            color: #fefefe;
            border: 1px solid #ffa600;
            height: 43px;
            margin: 0 0 0 70px;
            font-size: 16px;
            padding: 4px 30px;
            & > img {
              margin: -4px 8px 0 0;
              width: 19px;
            }
          }
          .ppybs-btn:hover {
            color: #fff;
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
          }
        }
        .ppyb-filter {
          position: relative;
          .allCity_box {
            position: absolute;
            left: -20px;
            top: -10px;
            z-index: 1;
            width: 100%;
            background: #fff;
            border-radius: 8px;
            padding: 10px 20px 20px 20px;
            box-shadow: 0px 8px 40px 0px rgba(171, 183, 204, 0.5);
            .cur_area {
              display: flex;
              .area-left {
                width: 100px;
                font-size: 16px;
                margin: 10px 0 0 0;
                display: flex;
                align-items: flex-start;
                & > img {
                  cursor: pointer;
                  margin: 5px 0 0 5px;
                  width: 17px;
                  transform: rotate(180deg);
                }
              }
              .area-right {
                display: flex;
                flex-wrap: wrap;
                border-bottom: 1px solid #dfe5ef;
                padding-bottom: 10px;
                .arear-item {
                  transition: all 0.3s;
                  margin: 10px 15px;
                  font-size: 16px;
                  color: #6c7a93;
                  cursor: pointer;
                  &:hover {
                    color: #1e70ff;
                  }
                }
                .areari-active {
                  color: #1e70ff;
                }
              }
            }
            .hot_area {
              display: flex;
              margin: 15px 0 30px 0;
              .area-left {
                cursor: pointer;
                width: 77px;
                font-size: 16px;
                margin: 10px 20px 0 0;
                display: flex;
                align-items: flex-start;
                & > img {
                  margin: 5px 0 0 8px;
                  width: 17px;
                  transform: rotate(180deg);
                }
              }
              .area-right {
                display: flex;
                flex-wrap: wrap;
                // border-bottom: 1px solid #dfe5ef;
                padding-bottom: 10px;
                .arear-item {
                  transition: all 0.3s;
                  margin: 10px 15px;
                  font-size: 16px;
                  // color: #6c7a93;
                  cursor: pointer;
                  &:hover {
                    color: #1e70ff;
                  }
                }
                .areari-active {
                  color: #1e70ff;
                }
              }
            }
            .all-city {
              .all-city-inn {
                border-top: 1px solid #dfe5ef;
                border-left: 1px solid #dfe5ef;
                .all-city-inn-item {
                  display: flex;
                  align-items: center;
                  border-bottom: 1px solid #dfe5ef;
                  border-right: 1px solid #dfe5ef;
                  background: #eff2f7;
                  .all-city-inn-item-left {
                    width: 80px;
                    display: flex;
                    // align-items: center;
                    justify-content: center;
                    // background: #eff2f7;
                    font-size: 24px;
                    font-weight: 500;
                    color: #6c7a93;
                    border-right: 1px solid #dfe5ef;
                  }
                  .all-city-inn-item-rig {
                    display: flex;
                    flex-direction: column;
                    // flex-wrap: wrap;
                    width: calc(100% - 80px);
                    background: #fff;
                    .all-city-inn-item-rig-item {
                      border-bottom: 1px solid #dfe5ef;
                      border-left: 1px solid #dfe5ef;
                      background: #fff;
                    }
                    .all-city-inn-item-rig-item:last-of-type {
                      border-bottom: none;
                    }
                    .allCity-iir-left {
                      width: 100px;
                      font-size: 18px;
                      font-weight: 500;
                      color: #2b3749;
                      text-align: center;
                      padding: 0 15px;
                    }
                    .allCity-iir-rig {
                      font-size: 16px;
                      font-weight: 400;
                      color: #2b3749;
                      display: flex;
                      flex-wrap: wrap;
                      padding: 15px 0;
                      width: 100%;
                      .arear-item {
                        margin: 5px 35px 5px 0;
                        display: inline-block;
                        cursor: pointer;
                      }
                    }
                    .all-city-inn-item-rig-item {
                      display: flex;
                      align-items: center;
                    }
                  }
                  .all-city-inn-itemlong {
                    flex-wrap: wrap;
                  }
                }
                .all-city-inn-item-active {
                  background: #1e70ff;
                  .all-city-inn-item-left {
                    color: #fff;
                  }
                  .all-city-inn-item-rig .all-city-inn-item-rig-item-active {
                    background: #e8f0ff;
                    .areari-active {
                      color: #1e70ff;
                    }
                  }
                }
              }
            }
          }
          .ppybf-up {
            display: flex;
            .ppybfu-left {
              cursor: pointer;
              width: 100px;
              font-size: 16px;
              margin: 10px 0 0 0;
              display: flex;
              align-items: flex-start;
              & > img {
                margin: 3px 0 0 5px;
                width: 17px;
              }
            }
            .ppybfu-right {
              display: flex;
              flex-wrap: wrap;
              border-bottom: 1px solid #dfe5ef;
              padding-bottom: 10px;
              .ppybfur-item {
                transition: all 0.3s;
                margin: 10px 15px;
                font-size: 16px;
                color: #6c7a93;
                cursor: pointer;
                &:hover {
                  color: #1e70ff;
                }
              }
              .ppybf-active {
                color: #1e70ff;
              }
            }
          }
          .ppybf-down {
            display: flex;
            padding: 30px 0 0;
            .ppybfd-left {
              width: 83px;
              font-size: 16px;
              margin: 12px 20px 0 0;
            }
            .ppybfd-right {
              display: flex;
              flex-wrap: wrap;
              border-bottom: 1px solid #dfe5ef;
              padding-bottom: 14px;
              width: 100%;
              & > div {
                width: 100%;
                margin: 10px 0;
                transition: all 0.3s;
              }
              .ppybfdr-item {
                transition: all 0.3s;
                margin: 10px 15px;
                font-size: 16px;
                color: #6c7a93;
                cursor: pointer;
                &:hover {
                  color: #1e70ff;
                }
              }
              .ppybf-active {
                color: #1e70ff;
              }
            }
          }
        }
      }
    }
    .ppyb-filSta {
      @include cenTheWid;
      display: flex;
      padding-left: 25px;
      margin-top: 30px;
      & > div {
        background: #e8f0ff;
        border-radius: 4px;
        border: 1px solid #1e70ff;
        font-size: 14px;
        color: #1e70ff;
        padding: 3px 10px;
        margin-right: 25px;
        & > img {
          margin: 0 0 0 10px;
          cursor: pointer;
        }
      }
    }
    .ppyb-con {
      @include cenTheWid;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding-top: 25px;
      .ppybc-item {
        transition: all 0.3s;
        box-sizing: border-box;
        width: 50%;
        display: flex;
        margin: 15px 0;
        padding: 24px;
        border-radius: 8px;
        cursor: pointer;
        .ppybci-left {
          // width: 40%;
          height: 176px;
          width: 236px;
          margin: 0 28px 0 0;
          position: relative;
          .ppybcil-img {
            // width: 100%;
            width: 236px;
            height: 176px;
            border-radius: 5px;
          }
          .ppybcil-tips {
            position: absolute;
            bottom: 12px;
            left: 12px;
            height: 32px;
            line-height: 32px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 30px;
            display: flex;
            max-width: 215px;
            & > span {
              color: #fff;
              font-size: 16px;
              padding: 0 10px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            & > img {
              margin: 0 0px 0 15px;
              width: 14px;
            }
          }
          .ppybcil-tip {
            position: absolute;
            bottom: 12px;
            left: 0;
            height: 32px;
            line-height: 32px;
            background: #e8f0ff;
            // border-radius: 30px;
            display: flex;
            max-width: 215px;
            color: #1e70ff;
            padding: 0 10px;
          }
        }
        .ppybcil-lnIBox {
          display: flex;
          justify-content: center;
          border-radius: 5px;
          border: 1px solid #eff2f7;
          .ppybcil-tips {
            background: rgba(43, 55, 73, 0.5);
          }
          .ppybcil-img {
            width: 140px;
            height: 120px;
            margin-top: 16px;
          }
        }
        .ppybci-right {
          // position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 53%;
          & > h5 {
            margin: 0;
            font-size: 24px;
            font-weight: bold;
            // width: 240px;
            // overflow: hidden;
            // white-space: nowrap;
            // text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
          & > div {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            & > div {
              // display: flex;
              width: 100%;
              & > div {
                color: #6c7a93;
                font-size: 16px;
                // margin: 14px 0 0px;
                height: 30px;
                display: flex;
                align-items: center;
              }
              .ppybcir-adr > span {
                @include ellipsis;
                width: 100%;
              }
            }
            & > button {
              border-radius: 25px;
              border-color: #40a9ff;
              color: #40a9ff;
              font-size: 16px;
              height: 40px;
              // position: absolute;
              // left: 0;
              // bottom: 0;
            }
          }
          & img {
            margin: 0 10px 0 0;
          }
        }
      }
      .ppybc-item:hover {
        // transform: translateY(-1px);
        box-shadow: 1px 1px 10px 2px #ccc;
        div div button {
          background: #1e70ff;
          border-color: #1e70ff;
          color: #fff;
        }
      }
      .ppybc-conf {
        width: 100%;
        text-align: center;
        margin: 100px 0;
        div {
          height: 16px;
          font-size: 16px;
          font-weight: 400;
          color: #6c7a93;
          margin: 50px 0 0 0;
        }
      }
    }
  }
  .pagination {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding-left: 0;
    .rc-pagination-item {
      padding: 3px 6px;
      border: 1px solid #ccc;
      margin: 0 5px;
      border-radius: 2px;
      height: 30px;
      cursor: pointer;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    li {
      list-style: none;
      height: 30px;
      line-height: 30px;
      min-width: 30px;
    }
    .rc-pagination-item-active {
      font-weight: bold;
      color: #1890ff;
      border-color: #1890ff;
    }
    .rc-pagination-prev,
    .rc-pagination-next {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ccc;
      box-sizing: border-box;
      border-radius: 2px;
      margin: 0 5px;
      cursor: pointer;
    }
    .rc-pagination-jump-prev,
    .rc-pagination-jump-next {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 12px;
      color: #ccc;
      img {
        display: none;
      }
      &:hover {
        div {
          display: none;
        }
        img {
          display: block;
        }
      }
    }
    .rc-pagination-jump-prev {
      transform: rotate(180deg);
    }
    .rc-pagination-options {
      margin-left: 10px;
      input {
        padding: 0 7px;
        width: 44px;
        height: 24px;
        margin: 0 8px;
      }
    }
  }
}
